// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"014d545a90dcf2557b3676822382d1a3da9aa690"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

const sampleRate = parseFloat(process.env.SENTRY_SAMPLE_RATE || '0');

Sentry.init({
    dsn: 'https://e6c5a7b14a9b02446ee6dd1b1b31dd78@o811799.ingest.us.sentry.io/4506737233821696',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: sampleRate,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/app\.pluto\.bio/,
        /^https:\/\/pluto\.bio/,
        /^https:\/\/api\.pluto\.bio/,
        /^https:\/\/dev-api\.pluto\.bio/,
        /^https:\/\/staging-api\.pluto\.bio/,
    ],
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.browserTracingIntegration(),
    ],
});
